import { IShipmentStatusModel } from '../../api/TrackingApi/IShipmentStatusModel';
import { ShipmentStatus } from '../../api/Common/ShipmentStatus';

import { TApiRequest } from "../../hooks/useServiceApi";
import { config } from '../../config';

export function GetCurrentStatusRequest(trackingNumber: string, recaptchaToken: string): TApiRequest {
    return {
        url: `${config.apiUrl}${config.trackUrl}/currentStatus?trackingNumber=${trackingNumber}`,
        init: {
            mode: "cors",
            headers: {
                "Recaptcha-Token": recaptchaToken,
            },
          }
    }
}
export interface ShipmentStepResult {
    steps: ShipmentStep[];
    externalTrackingLoadingError: boolean;
    hasExtraHistory: boolean;
}

export interface ShipmentStep {
    isCurrent: boolean;
    isCompleted: boolean;
    isFuture: boolean,
    description: string;
    key: ShipmentStatus | string;
    date: Date | undefined;
    extraHistory?: boolean;
}

export function GetShipmentSteps(currentStatus: IShipmentStatusModel): ShipmentStepResult {
    return currentStatus.historySteps;
}
